/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { callAPI } from 'helpers';

// Components
import { Link } from 'react-router-dom';

// Hooks
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const SectionApplyTGATTPAT = () => {
  const { t } = useTranslation();
  const profile = useSelector((state) => _.get(state, 'app.user') || {});
  const applicable = true;

  const [loading, setLoading] = useState(false);
  const [applied, setApplied] = useState(true);
  // Initial fetch
  useEffect(() => {
    const fetchSummary = async () => {
      try {
        setLoading(true);
        const { result } = await callAPI({ url: '/applicants/me/apply-exam-summary/tgattpat' });
        // setApplied(Boolean(result.recent_confirmations.length));
        setLoading(false);
      } catch (error) {
        console.log({ error });
        setLoading(false);
      }
    };
    fetchSummary();
  }, []);

  if (loading) return null;
  if (!applied) return null;
  return (
    <div className='t-box -exam'>
      <h2 className='title'>{t('สมัครสอบ TGAT/TPAT2-5')}</h2>
      <div className='text-center'>
        <p>{t('สมัครสอบ TGAT/TPAT2-5')}</p>
        {!profile.picture ? (
          <p className='color-orange'>
            {t('กรุณาอัปโหลดรูปถ่ายผู้สมัคร เพื่อทำการสมัครสอบ')}
            <br />
            {t('สามารถแก้ไขได้ที่หน้า ข้อมูลการลงทะเบียน')}
          </p>
        ) : !profile.program_code ? (
          <p className="color-orange">
            {t('กรุณากรอกข้อมูลหลักสูตรการศึกษา เพื่อทำการสมัครสอบ')}
            <br />
            {t('สามารถแก้ไขได้ที่หน้า ข้อมูลการลงทะเบียน')}
          </p>
        ) : null}
        {!applicable ? (
          <p>
            <strong className='color-orange'>เปิดระบบวันที่ 29 ต.ค. 2567 เวลา 09.00 น.</strong>
          </p>
        ) : (profile.picture && profile.program_code) ? (
          <Link to='/profile/apply-exams/tgattpat' className='btn-main btn-noti'>
            {t('ดูรายละเอียดและสมัครสอบ')}
          </Link>
        ) : null}
      </div>
    </div>
  );
};

export default SectionApplyTGATTPAT;
